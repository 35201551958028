import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import styled from "styled-components";

const Price = styled.div`
    color: rgb(193, 33, 21);
    // background-color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
    font-size: 1em;
    width: 100%;
`;

const OrgPrice = styled.div`
    color: rgb(96, 96, 96);
    // font-weight: bold;
    font-size: 0.8em;
    width: 100%;
    // text-decoration: line-through;
`;

const Point = styled.div`
    color: rgb(96, 96, 96);
    // background-color: rgba(0, 0, 0, 0.4);
    // font-weight: bold;
    font-size: 0.8em;
    width: 100%;
`;

const SearchResultItemName = styled.span`
    font-size: 0.9em;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    &&& a {
        color: black;
        text-decoration: none;
    }
    &&& a:hover {
        // color: rgb(96, 96, 96);
        color: red;
    }
`;

const formatter = new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
});

const favicon = {
    Amazon: "https://www.amazon.co.jp/favicon.ico",
    Rakuten: "https://www.rakuten.co.jp/favicon.ico",
    Yahoo: "https://s.yimg.jp/c/icon/s/bsc/2.0/favicon.ico",
    Yodobashi: "https://www.yodobashi.com/favicon.ico",
    Qoo10: "https://www.qoo10.com/favicon.ico",
};

function ItemList(props) {
    const { searchResult } = props;

    return (
        <CardGroup style={{ display: "flex", flexFlow: "row wrap", margin: 0, justifyContent: "space-around" }}>
            {searchResult.map((item, index) => (
                <Card key={index} style={{ minWidth: 160, flex: "0 0 0", margin: 1 }}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <OverlayTrigger
                            placement={"auto-start"}
                            overlay={<Tooltip id={`tooltip-${item.url}`}>{item.name}</Tooltip>}
                        >
                            <Card.Img src={item.imgUrl} style={{ height: 160, objectFit: "cover" }} alt="Card image" />
                        </OverlayTrigger>
                    </a>
                    <img alt={item.shop} src={favicon[item.shop]} width="24" height="24" />
                    <SearchResultItemName>
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.name}
                        </a>
                    </SearchResultItemName>
                    <Price>実質:{formatter.format(item.price)}</Price>
                    <Point>
                        {item.point}ポイント ({item.pointRate}%)
                    </Point>
                    <OrgPrice>通常：{formatter.format(item.orgprice)}</OrgPrice>
                </Card>
            ))}
        </CardGroup>
    );
}
export default ItemList;
