import React, { useState, useEffect, useRef } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import axios from "axios";
import qs from "qs";
import SearchFormComponent from "./SearchFormComponent";
import ItemList from "./ItemList";
import styled from "styled-components";
import title from "./title.png";

const Summay = styled.summary`
    text-align: center;
    background-color: #afafaf;
`;

function App() {
    const [searchCondition, setSearchContidion] = useState({
        q: "",
        itemName: "",
        minPrice: "",
        maxPrice: "",
        itemCondition: [],
        shippingPayer: [],
        shippingMethod: [],
        itemStatus: [],
    });

    const [keywords, setKeywords] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [progress, setProgress] = useState(false);

    const inputEl = useRef(null);

    useEffect(() => {
        inputEl.current.focus();
        axios
            .get(process.env.REACT_APP_INITIALJSON_URL)
            .then((response) => {
                setKeywords(response.data.keywords);
                setSearchResult(response.data.items);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleItemName = (e) => {
        setSearchContidion({ ...searchCondition, q: e.target.value });
    };

    const handleKeyword = (e) => {
        inputEl.current.value = e.target.textContent;
        searchCondition.q = e.target.textContent;
        searchItem();
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            searchItem();
            e.preventDefault();
        }
    };

    const searchItem = () => {
        if (searchCondition.q.trim() === "") {
            return;
        }
        setSearchResult([]);
        setProgress(true);

        const params = qs.stringify(searchCondition, { arrayFormat: "comma" });
        axios
            .get(process.env.REACT_APP_API_URL + "?" + params)
            .then((response) => {
                console.log(response);
                setKeywords([]);
                setSearchResult(response.data.items);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setProgress(false);
            });
    };

    return (
        <Container fluid>
            <Modal show={progress}>
                <Modal.Header>横断検索中</Modal.Header>
                <Modal.Body>
                    <ProgressBar animated now="100" />
                </Modal.Body>
            </Modal>
            <Container style={{ padding: 10 }} fluid>
                <Row>
                    <Col md="auto">
                        <a href="https://byoudene.com/">
                            <img src={title} alt="秒で値" />
                        </a>
                    </Col>
                    <Col>
                        <div>４つの大手EC通販サイトを横断検索</div>
                        <div>おおよその価格帯を確認することができます。</div>
                    </Col>
                </Row>
                <Row style={{ padding: 5 }}>
                    <Col md={12}>
                        <Form>
                            <InputGroup>
                                <Form.Control
                                    ref={inputEl}
                                    type="text"
                                    name="query"
                                    placeholder="お探しの商品名を入力してください"
                                    onChange={handleItemName}
                                    onKeyPress={handleKeyPress}
                                />
                                <Button variant="primary" onClick={searchItem}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="white"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </Button>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col md="auto">
                        <div className="d-md-none">
                            <details>
                                <Summay>詳細検索</Summay>
                                <SearchFormComponent {...{ searchCondition, setSearchContidion, searchItem }} />
                            </details>
                        </div>
                        <div className="d-none d-md-block">
                            <SearchFormComponent {...{ searchCondition, setSearchContidion, searchItem }} />
                            <hr></hr>
                            <Card style={{ width: 275, padding: 5 }}>
                                <a href="https://fritoku.com/" target="_blank" rel="noopener noreferrer">
                                    <Card.Img src="https://fritoku.com/wpside_400x210.webp" />
                                </a>
                                <Card.Body>
                                    <Card.Text>
                                        フリマサイト横断検索に特化したサイトです。最安値の限定品をゲットしましょう！！
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <hr></hr>
                            <Card style={{ width: 275, padding: 5 }}>
                                <a href="https://amatan.net/" target="_blank" rel="noopener noreferrer">
                                    <Card.Img src="https://amatan.net/wpside_400x210.webp" />
                                </a>
                                <Card.Body>
                                    <Card.Text>
                                        Amazonタイムセール検索に特化したサイトです。数量限定タイムセール品を簡単に探せます。
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        {keywords.length > 0 && (
                            <Row style={{ padding: 5 }}>
                                <Col>
                                    注目キーワード：
                                    <Button onClick={handleKeyword}>{keywords[0]}</Button>　
                                    <Button onClick={handleKeyword}>{keywords[1]}</Button>　
                                    <Button onClick={handleKeyword}>{keywords[2]}</Button>　
                                </Col>
                            </Row>
                        )}
                        <ItemList {...{ searchCondition, searchResult }} />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default App;
